import {Injectable} from '@angular/core';
import {
  Action,
  State,
  Selector,
  StateContext, createSelector,
} from '@ngxs/store';
import {patch} from '@ngxs/store/operators';
import {BandStateModel} from './band-state.models';
import {BandStateActions} from "./band-state.actions";
import {
  BandmanagerRestApiBand,
  BandmanagerRestApiBandStatsDto,
  BandmanagerRestApiBankaccount,
  BandmanagerRestApiPlayerWithRanksDto,
  BandmanagerRestApiUniform
} from "@digitale-menschen/bandmanager-rest-api";
import {RehearsalInfosInterface} from "../../app/models/rehearsal-infos.interface";
import {state} from "@angular/animations";

const STATE_NAME = 'BandState';

@State<BandStateModel>({
  name: STATE_NAME,
  defaults: {
    band: null,
    bankAccount: null,
    uniform: null,
    stats: null,
    players: null,
    lastRehearsal: null
  },
})
@Injectable()
export class BandState {
  @Selector()
  static band(state: BandStateModel): BandmanagerRestApiBand | null {
    return state.band;
  }

  @Selector()
  static bankAccount(state: BandStateModel): BandmanagerRestApiBankaccount | null {
    return state.bankAccount;
  }

  @Selector()
  static stats(state: BandStateModel): BandmanagerRestApiBandStatsDto | null {
    return state.stats;
  }

  @Selector()
  static statsPercentDone(state: BandStateModel): number {
    if (!state || !state.stats || !state.stats.targetPointsOfNextSection) {
      return 0;
    } else {
      return ((state.stats.pointsTotal - state.stats.startPointsOfCurrentSection) / (state.stats.targetPointsOfNextSection - state.stats.startPointsOfCurrentSection)) * 100;
    }
  }

  @Selector()
  static uniform(state: BandStateModel): BandmanagerRestApiUniform | null {
    return state.uniform;
  }

  @Selector()
  static lastRehearsal(state: BandStateModel): RehearsalInfosInterface | null {
    return state.lastRehearsal;
  }

  @Selector()
  static players(state: BandStateModel): BandmanagerRestApiPlayerWithRanksDto[] | null {
    return state.players;
  }

  static playerById(id: string) {
    return createSelector([BandState], (state: BandStateModel): BandmanagerRestApiPlayerWithRanksDto | null => {
      if (state.players && state.players.length) {
        return state.players.find((item) => item.id === id) || null;
      } else {
        return null;
      }
    });
  }

  @Action(BandStateActions.SetBand)
  setBand(ctx: StateContext<BandStateModel>, action: BandStateActions.SetBand) {
    ctx.setState(patch<BandStateModel>({
      band: action.band
    }));
  }

  @Action(BandStateActions.SetBankAccount)
  setBankAccount(ctx: StateContext<BandStateModel>, action: BandStateActions.SetBankAccount) {
    ctx.setState(patch<BandStateModel>({
      bankAccount: action.bankAccount
    }));
  }

  @Action(BandStateActions.SetStats)
  setStats(ctx: StateContext<BandStateModel>, action: BandStateActions.SetStats) {
    ctx.setState(patch<BandStateModel>({
      stats: action.stats
    }));
  }

  @Action(BandStateActions.SetUniform)
  setUniform(ctx: StateContext<BandStateModel>, action: BandStateActions.SetUniform) {
    ctx.setState(patch<BandStateModel>({
      uniform: action.uniform
    }));
  }

  @Action(BandStateActions.SetRehearsal)
  setRehearsal(ctx: StateContext<BandStateModel>, action: BandStateActions.SetRehearsal) {
    ctx.setState(patch<BandStateModel>({
      lastRehearsal: action.rehearsal
    }));
  }

  @Action(BandStateActions.SetPlayers)
  setPlayers(ctx: StateContext<BandStateModel>, action: BandStateActions.SetPlayers) {
    ctx.setState(patch<BandStateModel>({
      players: action.players
    }));
  }

  @Action(BandStateActions.UpdatePlayer)
  updatePlayer(ctx: StateContext<BandStateModel>, action: BandStateActions.UpdatePlayer) {
    const state = ctx.getState();
    const playerList = state.players || [];
    const index = playerList.findIndex((player) => player.id === action.player.id);
    if (index > -1) {
      const newList = JSON.parse(JSON.stringify(playerList)) as BandmanagerRestApiPlayerWithRanksDto[];
      newList[index] = action.player;
      ctx.setState(patch<BandStateModel>({
        players: newList
      }));
      this.updateBandPoints(ctx);
    }
  }

  /**
   * Update Band.pointsTotal & Band.pointsUnassigned
   * @param ctx
   * @private
   */
  private updateBandPoints(ctx: StateContext<BandStateModel>): void {
    const state = ctx.getState();
    const playerList = state.players || [];
    if (state.band && playerList.length > 0) {
      let bandNewTotalPoints = 0;
      playerList.forEach((player) => {
        bandNewTotalPoints = bandNewTotalPoints + player.pointsTotal;
      });

      const bandNewUnassignedPoints = state.band.pointsTotal - bandNewTotalPoints;
      const bandNewTotals = {
        ...state.band,
        pointsTotal: bandNewTotalPoints,
        pointsUnassigned: bandNewUnassignedPoints
      };

      ctx.setState(patch<BandStateModel>({
        band: bandNewTotals
      }));
    }
  }
}

